import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
} from './styles'

const Feed = () => {
    const images = [
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_2666.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_2971.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_2985.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_3304.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_3842.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_4042-2.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_4205.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_3625.jpg',
        'https://universoparalello.org/wp-content/uploads/2021/10/LAM_3621.jpg',
        'https://universoparalello.org/wp-content/uploads/2018/11/ALEX-TORRES-UP14-coletiva.a.mente-%C3%81LBUM-3-33.jpg',
        'https://universoparalello.org/wp-content/uploads/2018/11/ALEX-TORRES-UP14-coletiva.a.mente-%C3%81LBUM-3-18.jpg',
    ]
    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className="no-scroll">
            { 
                images.map(image => {
                    return (
                        <Wrapper key={image}>
                            <Image img={`${image}`} />
                        </Wrapper>
                    )
                }) 
            }
            </ListContainer>
        </Container>
    )
}

export default Feed